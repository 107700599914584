import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layouts/blogPost.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "individual-freedom-is-our-top-priority",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#individual-freedom-is-our-top-priority",
        "aria-label": "individual freedom is our top priority permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Individual freedom is our top priority`}</h1>
    <p>{`At Nerve, we firmly believe in the sovereignty of the individual. Change happens bottom-up, not top-down. We see people as free agents,
who in aggregate define the societal structure and shift paradigms. Code is law. We, as an entity, will always encourage independent thinking and action instead of coercion.`}</p>
    <h1 {...{
      "id": "trust-and-courage-are-the-basis-of-our-society",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#trust-and-courage-are-the-basis-of-our-society",
        "aria-label": "trust and courage are the basis of our society permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Trust and courage are the basis of our society`}</h1>
    <p>{`The mainstream is all about trustless interactions brought by blockchain technology. This is important for the deterministic execution of transactions on the blockchain.
However, we focus on highly dynamic direct exchange and social interaction between people, in particular in the non-digital world.
It is naive to trust anyone, but it is at the same time very cynical to make distrust the basis of human interaction. We firmly believe that the majority of people are able
to trust each other and acknowledge that it takes courage to do so.`}</p>
    <h1 {...{
      "id": "morals-and-ethics-are-not-merely-defined-by-laws",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#morals-and-ethics-are-not-merely-defined-by-laws",
        "aria-label": "morals and ethics are not merely defined by laws permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Morals and ethics are not merely defined by laws`}</h1>
    <p>{`With a vast variety in cultural and communicative norms coming together on the internet, the world appears more diverse than ever. As a global and borderless enterprise operating entirely on the blockchain, it seems obvious to us that we cannot define our morals and ethics based on local legislation.
Our moral and ethical principles have to be universal, rather than based on local law.`}</p>
    <h1 {...{
      "id": "earning-without-exploiting",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#earning-without-exploiting",
        "aria-label": "earning without exploiting permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Earning without exploiting`}</h1>
    <p>{`The blockchain environment is full of scams and exploits and we want to dissociate as far as possible from such practices.
We believe in capitalist market making and strive to establish our ecosystem by offering products and services that are in demand.`}</p>
    <h1 {...{
      "id": "our-goals-are-not-defined-by-profit",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#our-goals-are-not-defined-by-profit",
        "aria-label": "our goals are not defined by profit permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Our goals are not defined by profit`}</h1>
    <p>{`We strive to enable structural changes in society that are much more important to us than mere profit interests. We will never give up on our principles in favor of potential profits.`}</p>
    <h1 {...{
      "id": "we-are-the-users-of-our-own-products",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#we-are-the-users-of-our-own-products",
        "aria-label": "we are the users of our own products permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`We are the users of our own products`}</h1>
    <p>{`We develop our products, not only because we want to see them flourish in the marketplace but also, because we want to use them ourselves. As a result we have a consumer-centristic view, which we deem superior compared to large corporations.`}</p>
    <h1 {...{
      "id": "open-neutral-public-borderless-censorship-resistant",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#open-neutral-public-borderless-censorship-resistant",
        "aria-label": "open neutral public borderless censorship resistant permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Open, neutral, public, borderless, censorship resistant`}</h1>
    <p>{`These are the five pillars of blockchain and we pledge to always comply. Anything violating these principles is deemed impractical and will not be considered a viable option for our endeavors.`}</p>
    <h1 {...{
      "id": "either-no-laws-or-all-laws-apply",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#either-no-laws-or-all-laws-apply",
        "aria-label": "either no laws or all laws apply permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Either no laws or all laws apply`}</h1>
    <p>{`As a global and borderless enterprise the only global law we know is our open source, publicly verifiable code. We will make sure that our systems cannot be shut down or controlled by anyone. We write unstoppable code.`}</p>
    <h1 {...{
      "id": "always-question-the-status-quo",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#always-question-the-status-quo",
        "aria-label": "always question the status quo permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Always question the status quo`}</h1>
    <p>{`As blockchain pioneers, we believe in the value of disruption and the resulting advancements. We believe in the explorative nature of human beings and are willing to take on personal risks to bring forth new developments, while operating on the cutting edge of technology. We are striving to create more entropy in society.`}</p>
    <h1 {...{
      "id": "fail-faster",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#fail-faster",
        "aria-label": "fail faster permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Fail faster`}</h1>
    <p>{`People learn best from their mistakes. Instead of discouraging people from making mistakes, we encourage people to make mistakes and learn from them.
The more the better – feel free to explore and advance.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      